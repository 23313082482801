import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import { Droppable } from 'react-beautiful-dnd';

import SongCard from '../KhaitaCollections/SongCard/SongCard';

const useStyles = makeStyles(theme => ({
    root: {
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2),
        marginTop: theme.spacing(5),
        //marginBottom: theme.spacing(4),
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        paddingRight: theme.spacing(2),
        margin: '1px !important',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleCollection: {
        marginLeft: theme.spacing(1),
        //flex: 1,
        display: 'inline-block',
        //display: 'table-cell',
        verticalAlign: 'text-top',
        marginTop: theme.spacing(0),
    },
    collectionControls: {
        //display: 'inline-block',
        display: 'inline-table',
        width: '100%'
    }
}));

export default function SingleLineCollection(props) {
    const classes = useStyles();

    const songListArray = props.songListArray;

    //console.log('single line props', props);

    return (
        <div className={classes.root}>
            <div>
                <Droppable droppableId={props.songCollectionId} direction='horizontal'>
                    {(provided)=>(
                        <GridList 
                            className={classes.gridList} 
                            innerRef={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {songListArray.map((item, index) =>                     
                                <SongCard 
                                    key={(item.id === '' || (item.id === undefined)) ? item.url : item.id} 
                                    item={item} 
                                    navigation={props.navigation} 
                                    linkBack={props.linkBack} 
                                    songsNumColl={props.songsNumColl}
                                    lastSong={props.lastSong}
                                    index={index}
                                    setLastSongFromCard={props.setLastSongFromCard}
                                />               
                            )} 
                            {provided.placeholder} 
                        </GridList>
                    )}
                </Droppable>
            </div>
      </div>
    );
}