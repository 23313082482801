import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import DoneIcon from '@material-ui/icons/Done';
import { greenColor } from './constants';

const useStyles = makeStyles((theme) => ({
    progressRoot: {
        textAlign: 'center',
        padding: '20%',
    },
    progress: {
        display: 'inline-block',
        margin: 'auto',
        width: '100%',
        height: '100%'
    },
}));

export default function Success() {
    const classes = useStyles();
    return (
        <div className={classes.progressRoot}>
            <div className={classes.progress}>
                <DoneIcon style={{ fontSize: 50, color: greenColor }} />
            </div>
        </div>
    );
}