const getUrlThumbnail = (url) => {
    let code = '';
    if (url.trim().indexOf('https://youtu.be/') === 0) {
        //https://youtu.be/Tl4047PN4oI
        code = url.trim().slice(17);
    } else {
        //https://www.youtube.com/watch?v=lSb4CDyrkSM
        code = url.trim().substring(url.indexOf('=') + 1);
    }
    return `https://i.ytimg.com/vi/${code}/0.jpg`;
};

export default getUrlThumbnail;