import React, { Component }from 'react';
import ReactPlayer from 'react-player';

import { getLocalAutoplay } from '../../KhaitaPlayer/AutoplayLocal';
import { SongInfoSkeleton } from '../../KhaitaPlayer/KhaitaPlayerSkeleton';
import PlayerControls from './PlayerControls';
import CurrentSongInfo from './CurrentSongInfo';
import CurrentSongLyrics from './CurrentSongLyrics';

class PlaylistPlayer extends Component {
    constructor(props) {
        super(props);
        this.onReady = this.onReady.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPlayVideo = this.onPlayVideo.bind(this);
        this.state = {
            currentVideo: {},
            pause: true,
            first: true,
            last: false,
            //error: false,
        }
    }

    componentWillUnmount() {
        if (this.YT !== null && this.YT !== undefined) {
            this.YT.destroy();
        }
    }

    onReady = event => {
        this.YT = event.getInternalPlayer();
        if (getLocalAutoplay()) {
            //debugger;
            //console.log('on ready FS');
            if (this.YT !== null && this.YT !== undefined) {
                this.YT.playVideo();
            }
        }
        if (this.YT !== null && this.YT !== undefined) {
            this.setState({
                currentVideo: this.props.playlistData[this.YT.getPlaylistIndex()],
                //error: false,
            })
        }
    }

    onError = (error) => {
        /*this.setState({
            error: true
        });*/
    }

    onPlay = () => {
        if (this.YT !== null && this.YT !== undefined) {
            this.YT.playVideo();
        }
    }

    onPause = () => {
        if (this.YT !== null && this.YT !== undefined) {
            this.YT.pauseVideo();
        }
    }

    onNext = () => {
        if (this.YT !== null && this.YT !== undefined) {
            this.YT.nextVideo();
            this.setState({
                currentVideo: this.props.playlistData[this.YT.getPlaylistIndex()+1],
                first: (this.YT.getPlaylistIndex() === 0),
                last: (this.YT.getPlaylistIndex() === this.props.playlistData.length - 1)
            });
        }
    }

    onPrev = () => {
        if (this.YT !== null && this.YT !== undefined) {
            this.YT.previousVideo();
            this.setState({
                currentVideo: this.props.playlistData[this.YT.getPlaylistIndex()-1],
                first: (this.YT.getPlaylistIndex() === 0),
                last: (this.YT.getPlaylistIndex() === this.props.playlistData.length - 1)
            })
        }
    }

    onPlayVideo = () => {
        if (this.YT !== null && this.YT !== undefined) {
            this.setState({
                currentVideo: this.props.playlistData[this.YT.getPlaylistIndex()],
                pause: false,
                first: (this.YT.getPlaylistIndex() === 0),
                last: (this.YT.getPlaylistIndex() === this.props.playlistData.length - 1)
            });
        }
    }

    onPauseVideo = () => {
        if (this.YT !== null && this.YT !== undefined) {
            this.setState({
                currentVideo: this.props.playlistData[this.YT.getPlaylistIndex()],
                pause: true,
                first: (this.YT.getPlaylistIndex() === 0),
                last: (this.YT.getPlaylistIndex() === this.props.playlistData.length - 1)
            })
        }
    }

    ref = player => {
        this.player = player;
    }

    render () {
        return (
            <>
                {this.YT === null || this.YT === undefined 
                    || this.state.currentVideo.url === undefined || this.state.currentVideo.url === '' 
                    ? <SongInfoSkeleton/>
                    : <CurrentSongInfo 
                        currentVideo={this.state.currentVideo}
                        //error={this.state.error}
                        linkBack={this.props.linkBack} 
                        navigation={this.props.navigation}
                        songsNumColl={this.props.songsNumColl}
                    />
                }
                <div className={this.props.classes.playerWrapper} >
                    <ReactPlayer
                        ref={this.ref}
                        id='video'
                        className={this.props.classes.reactPlayer}
                        url={this.props.link}
                        controls 
                        width='100%' height='100%'
                        pip={this.props.pip}
                        config={{
                            youtube: {
                                playerVars: {
                                    //'autoplay': 1, 
                                    'controls': 2,
                                    //'autohide': 1, 'wmode': 'opaque',
                                    'origin': 'https://khaita.com',
                                    'fs': 1, 'modestbranding': 1,
                                    'rel': 0, 'showinfo': 0,
                                    'iv_load_policy': 3,
                                    'enablejsapi': 1, 
                                    'playsinline': 1 //0-fullscreen, 1-on the page
                                },
                                preload: true,
                            },
                        }}
                        onReady={this.onReady}
                        onEnded={this.props.handleVideoEnd}
                        onPlay={this.onPlayVideo}
                        onPause={this.onPauseVideo}
                        onError={this.onError}
                    /> 
                </div>
                <PlayerControls
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    pause={this.state.pause}
                    first={this.state.first}
                    last={this.state.last}
                />
                <CurrentSongLyrics
                    currentVideo={this.state.currentVideo}
                />
            </>
        )
    }
}

export default PlaylistPlayer;