const firebase = require("firebase/app");
// Required for side-effects
require("firebase/firestore");

var firebaseConfig = {
    apiKey: "AIzaSyD5UEW6kzqU0i6RYWNGKhsO-rmcpt9P4iQ",
    authDomain: "khaita-app.firebaseapp.com",
    databaseURL: "https://khaita-app.firebaseio.com",
    projectId: "khaita-app",
    storageBucket: "",
    messagingSenderId: "628336077356",
    appId: "1:628336077356:web:fbeb8917bf3c9749",
    measurementId: "G-QZ0RHNH54R"
};
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();

db.enablePersistence()
.catch(function(err) {
    if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});

export { db }