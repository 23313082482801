import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import NextIcon from '@material-ui/icons/SkipNext';
import PreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';

import { isIos } from '../../Utils/isIos';

export default function PlayerControls (props) {
    return (
        <div style={{textAlign: 'center'}}>
            <IconButton onClick={props.onPrev} disabled={props.first}>
                <PreviousIcon/>
            </IconButton>
            { props.pause 
                ? <IconButton onClick={props.onPlay}>
                    <PlayIcon/>
                </IconButton>
                : <IconButton onClick={props.onPause}>
                    <PauseIcon/>
                </IconButton>
            }
            <IconButton onClick={props.onNext} disabled={props.last}>
                <NextIcon/>
            </IconButton>
        </div>
    ) 
    /*if (isIos()) {
        return (
            <div style={{textAlign: 'center'}}>
                <IconButton onClick={props.onPrev} disabled={props.first}>
                    <PreviousIcon/>
                </IconButton>
                { props.pause 
                    ? <IconButton onClick={props.onPlay}>
                        <PlayIcon/>
                    </IconButton>
                    : <IconButton onClick={props.onPause}>
                        <PauseIcon/>
                    </IconButton>
                }
                <IconButton onClick={props.onNext} disabled={props.last}>
                    <NextIcon/>
                </IconButton>
            </div>
        ) 
    } else {
        return null;
    }*/
}