import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SortIcon from '@material-ui/icons/Sort';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import KhaitaPlayer from './KhaitaPlayer';

const useStyles = makeStyles(theme => ({
    player: {
        display: 'table-cell',
        float: 'right',
    },
    toggleGroup: {
        display: 'contents'
    }

}));

export default function KhaitaPlayerSettings(props) {
    const classes = useStyles();
    const [sort, setSort] = React.useState(props.isShuffled ? 'shuffle' : 'sort');
  
    useEffect(() => {
        setSort(props.isShuffled ? 'shuffle' : 'sort');
    }, [props.isShuffled]);

    const onChange = (event, newSort) => {
        if (newSort === null && sort === 'sort') {
            return;
        } else {
            if (newSort === 'sort') {
                props.onSort();
            } else {
                props.onShuffle();
            }
        }
    }

    return (
        <div className={classes.player}>
            <KhaitaPlayer 
                songList={props.songList} 
                titleCollection={props.titleCollection}
                songsNumColl={props.songsNumColl}
                linkBack={props.linkBack} 
            />
            <ToggleButtonGroup 
                size='small'
                className={classes.toggleGroup}
                value={sort}
                exclusive
                onChange={onChange}
                //aria-label="text alignment"
            >
                <ToggleButton value="shuffle" aria-label="Shuffle">
                    <ShuffleIcon fontSize='small'/>      
                </ToggleButton>
                <ToggleButton value="sort" aria-label="Order">
                    <SortIcon fontSize='small'/>  
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    )
}