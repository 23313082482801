import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { IconButton, Link, Typography, makeStyles } from '@material-ui/core';
import { DialogTitle, DialogContent, Transition } from '../Utils/Dialog';
import HelpIcon from '@material-ui/icons/Help';

import { fontColor } from '../Utils/constants';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(3),
  },
  iconWord: {
    color: fontColor,
    position: 'relative',
    left: -theme.spacing(2),
  },
  p: {
    //marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function SongInfo(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        //size='small'
        onClick={handleOpen}
        className={classes.iconWord}
      >
        <HelpIcon fontSize='small' />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth='xs'
        //aria-labelledby="alert-dialog-slide-title"
        //aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id='song-info' onClose={handleClose}>
          Drajyor
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.p}>
            The Drájyór (སྒྲ་སྦྱོར་ sgra sbyor = སྒྲ་ sound སྦྱོར་ adjust) is a
            phonetic transcription system for Tibetan language devised by
            Chögyal Namkhai Norbu.
          </Typography>
          <Typography className={classes.p}>
            Discover more about{' '}
            <Link
              href='https://www.shangshunguk.org/%E0%BD%A6%E0%BE%92%E0%BE%B2%E0%BC%8B%E0%BD%A6%E0%BE%A6%E0%BE%B1%E0%BD%BC%E0%BD%A2%E0%BC%8D-drajyor-a-phonetic-transcription-system-for-tibetan/'
              target='_blank'
              rel='noopener'
            >
              Drajyor
            </Link>
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
