import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    card: {
        //duplicate with SongCard!
        minWidth: 210,
        width: 210,
        margin: '0.5em',
        textDecoration: 'none',
    },
    cardContent: {
        paddingBottom: '0.5em',
        overflow: 'hidden',
        "&:last-child": {
            paddingBottom: '0.5em'
        },
        paddingTop: '0.5em',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
});

export default function SongCardSkeleton () {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <Skeleton variant='rect' animation={'wave'} height={108} />
            <CardContent className={classes.cardContent}>
                <Skeleton variant='text' animation={'wave'} height={'2.3em'} />
                <Skeleton variant='text' animation={'wave'} height={'1.5em'} width="80%" />
                <Skeleton variant='text' animation={'wave'} height={'1.5em'} width="60%" />
                <Skeleton variant='text' animation={'wave'} height={'1.5em'} width="10%" />
            </CardContent>
        </Card> 
    )
}

//className={classes.skeletonText}