import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PlaylistPlayer from './PlaylistPlayer/PlaylistPlayer';
import VideoPlayer from './VideoPlayer';
import { fontColor } from '../Utils/constants';

const useStyles = makeStyles((theme) => ({
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
        //top: theme.spacing(1),
    },    
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
        //padding: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(2),
        color: fontColor,
    },
    centered: {
        textAlign: 'center'
    },
    goToDiv: {
        float: 'right'
    }
}));

export default function VideoContainer(props) {
    const classes = useStyles();

    if (props.open !== undefined && !props.open) {
        return null;
    }

    if (props.open) {
        return(
            <PlaylistPlayer
                link={props.link}
                urls={props.urls}
                classes={classes}
                handleVideoEnd={props.handleVideoEnd}
                playlistData={props.playlistData}
                linkBack={props.linkBack}
                navigation={props.navigation}
                songsNumColl={props.songsNumColl}
            />
        ); 
    } else {
        return(
            <VideoPlayer
                link={props.link}
                //urls={props.urls}
                classes={classes}
                handleVideoEnd={props.handleVideoEnd}
                //playlistData={props.playlistData}
                //linkBack={props.linkBack}
                //navigation={props.navigation}
            />
        );
    }
}