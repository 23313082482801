import React, { useState }from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Lyrics from '../../SongPage/Lyrics';

export default function CurrentSongLyrics (props) {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange} 
            //className={classes.paper}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography color='textSecondary' variant="overline">LYRICS</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Lyrics 
                    songUrl={props.currentVideo.url} 
                    lyrics={props.currentVideo.lyrics} 
                    title={props.currentVideo.title}
                    dontShowFullScreen={true}
                />
            </AccordionDetails>
        </Accordion>
    )
}
