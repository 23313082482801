import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import VideoContainer from '../VideoContainer/VideoContainer';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2), 
        marginTop: theme.spacing(1),
    },
    videoTab: {
        minWidth: 'auto',
        ["@media (min-width:600px)"]: { paddingLeft: '20px', paddingRight: '20px' }
    }
}));

export default function SongVideo(props) {
    const [value, setValue] = useState(props.tab === '' ? 'song' : props.tab);

    useEffect(() => {
        if ((props.video[value] === undefined) || (props.video[value].trim() === '')) {
            setValue('song');
        }
    }, [props]); 

    const classes = useStyles();

    const handleVideoEnd = () => {
        props.handleVideoEnd(value);
    }

    const handleChange = (event, value) => {
        setValue(value);
    }

    if ((props.video[value] === undefined) || (props.video[value].trim() === '')) {
        return null;
    }

    return(
        <div className={classes.root}>
            <Tabs value={value} 
                onChange={handleChange}
                variant="scrollable" 
                textColor="secondary"
            >
                <Tab 
                    value={'song'}
                    key={'S'}
                    label='Song'
                    className={classes.videoTab}
                />
                {props.video.dance.replace(/\s/g,'') === '' 
                    ? null 
                    : <Tab 
                        value={'dance'} 
                        key={'D'} 
                        label='Dance'
                        className={classes.videoTab}
                    />
                }
                {props.video.commentary.replace(/\s/g,'') === '' 
                    ? null 
                    : <Tab 
                        value={'commentary'} 
                        key={'C'} 
                        label='About'
                        className={classes.videoTab}
                    />
                }
                {(props.video.tutorial === undefined) || (props.video.tutorial.replace(/\s/g,'') === '') 
                    ? null 
                    : <Tab 
                        value={'tutorial'} 
                        key={'L'} 
                        label='Learn'
                        className={classes.videoTab}
                    />
                }
            </Tabs>
            {value === 'song' && <VideoContainer link={props.video.song} key={'O'} id={'song'} handleVideoEnd={handleVideoEnd}/>}                  
            {value === 'dance' && <VideoContainer link={props.video.dance} key={'D'} id={'dance'} handleVideoEnd={handleVideoEnd}/>}
            {value === 'commentary' && <VideoContainer link={props.video.commentary} key={'C'} id={'commentary'}/>}          
            {value === 'tutorial' && <VideoContainer link={props.video.tutorial} key={'L'} id={'learn'} />}          
        </div>
    );
}