import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { makeStyles, IconButton } from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
    inlineIcon: {
        display: 'inline-block'
    },
}));

export default function SocialButtons() {
    const classes = useStyles();
    return(
        <div>
            <IconButton
                variant="link"
                color="default"
                //className={classes.signInButton}
                //startIcon={<FacebookIcon />}
                href="https://www.youtube.com/user/HarmonyInTheSpace"
                target="_blank"
                rel="noopener"
                className={classes.inlineIcon}
            >
                <YouTubeIcon/>
            </IconButton>
            <IconButton
                variant="link"
                color="default"
                //className={classes.signInButton}
                //startIcon={<FacebookIcon />}
                href="https://www.instagram.com/khaita_harmony.in.space"
                target="_blank"
                rel="noopener"
                className={classes.inlineIcon}
            >
                <InstagramIcon/>
            </IconButton>
            <IconButton
                variant="link"
                color="default"
                //className={classes.signInButton}
                //startIcon={<FacebookIcon />}
                href="https://www.facebook.com/harmonyinthespace"
                target="_blank"
                rel="noopener"
                className={classes.inlineIcon}
            >
                <FacebookIcon/>
            </IconButton> 
        </div>
    )
}