import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css';

const useStyles = makeStyles((theme) => ({
    progressRoot: {
        textAlign: 'center',
        padding: '20%',
    },
    progress: {
        display: 'inline-block',
        margin: 'auto',
        width: '100%',
        height: '100%'
    },
}));

export default function Progress() {
    const classes = useStyles();
    return (
        <div className={classes.progressRoot}>
            <div className={classes.progress}>
                <CircularProgress variant='indeterminate'/>
            </div>
        </div>
    );
}