import React from 'react';
import GridList from '@material-ui/core/GridList';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

import SongCardSkeleton from '../SongCard/SongCardSkeleton';

const useStyles = makeStyles(theme => ({
    root: {
        flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(2),
        marginTop: theme.spacing(5),
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        paddingRight: theme.spacing(2),
    },
    titleCollection: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
}));

export default function SongListSkeleton() {
    const classes = useStyles();
    const emptyArray = [1, 2, 3, 4, 5, 6, 7]
    return (
        <div className={classes.root}>
            <Skeleton variant='text' animation={'wave'} height={'2.4em'} width={100} className={classes.titleCollection}/>
            <div>
                <GridList className={classes.gridList} cols={2.5}>
                    {emptyArray.map((item) => <SongCardSkeleton key={item}/>)}   
                </GridList>
            </div>
        </div>
    )
}