import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';

import { backgroundColor, fontColor, khaitaColor } from '../Utils/constants';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    padding: '1.5em',
    boxShadow: ' 0 0 4px 0 rgba(0, 0, 0, 0.12)',
    backgroundColor: backgroundColor,
    minHeight: '85vh',
    textAlign: 'center',
  },
  p: {
    marginTop: theme.spacing(4),
    //marginBottom: theme.spacing(2),
  },
  h1: {
    //fontSize: '2rem',
    //paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  icon: {
    color: fontColor,
    margin: theme.spacing(2),
  },
  myIcon: {
    color: khaitaColor, //'#f50057',
    margin: theme.spacing(2),
  },
}));

function WelcomeToMyKhaita() {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.root}>
        <Container maxWidth='sm'>
          <Typography variant='h5' component='h1' className={classes.h1}>
            Create a playlist of your favourite <br />
            Khaita songs and dances
          </Typography>
          <div className={classes.p}>
            <Typography>To add the songs here,</Typography>
            <Typography>tap the heart button</Typography>
            <Typography>on the pages of your favourite songs</Typography>
            <FavoriteIcon className={classes.icon} />
          </div>
        </Container>
      </div>
    </Fragment>
  );
}

export default WelcomeToMyKhaita;
