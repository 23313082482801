import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();


serviceWorker.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting
    
        if (waitingServiceWorker) {
            if (window.confirm('New update available! Ready to see the latest changes?')) {
                waitingServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    window.location.reload()
                }
                });
                waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
            }
        }
    }
});
