import React, { Component }from 'react';
import ReactPlayer from 'react-player';

import { getLocalAutoplay } from '../KhaitaPlayer/AutoplayLocal';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.onReady = this.onReady.bind(this);
        this.state = {
            currentVideo: {},
            pause: true,
            first: true,
            last: false,
            error: false,
        };
    }

    componentWillUnmount() {
        if (this.YT !== null && this.YT !== undefined) {
            this.YT.destroy();
        }
    }

    onReady = event => {
        this.YT = event.getInternalPlayer();
        if (getLocalAutoplay()) {
            //debugger;
            //console.log('on ready FS');
            if (this.YT !== null && this.YT !== undefined) {
                this.YT.playVideo();
            }
        }
    }

    onError = (error) => {
        debugger
        //console.log('player error', error);
        this.setState({
            error: true
        });
    }

    ref = player => {
        this.player = player;
    }

    render () {
        //debugger
        return (
            <div className={this.props.classes.playerWrapper} >
                <ReactPlayer
                    ref={this.ref}
                    id='video'
                    className={this.props.classes.reactPlayer}
                    url={this.props.link}
                    controls 
                    width='100%' height='100%'
                    pip={true}
                    config={{
                        youtube: {
                            playerVars: {
                                //'autoplay': 1, 
                                'controls': 2,
                                //'autohide': 1, 'wmode': 'opaque',
                                'origin': 'https://khaita.com',
                                'fs': 1, 'modestbranding': 1,
                                'rel': 0, 'showinfo': 0,
                                'iv_load_policy': 3,
                                'enablejsapi': 1, 
                                'playsinline': 1 //0-fullscreen, 1-on the page
                            },
                            preload: true,
                        },
                    }}
                    onReady={this.onReady}
                    onEnded={this.props.handleVideoEnd}
                    onError={this.onError}
                /> 
            </div>
        )
    }
}

export default VideoPlayer;