import {
    SET_MYKHAITA
} from "./MyKhaitaTypes"
  
export default (state, { type, payload }) => {
    //debugger;
    switch (type) {
        // Add new collection
        case SET_MYKHAITA:
            return payload
        default:
            return state
    }
}