import React, { Fragment } from 'react';
import { IconButton, Button, TextField } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { DialogTitle, DialogContent } from '../Utils/Dialog';

import { fontColor } from './constants';

const useStyles = makeStyles(theme => ({
    rootOnScroll: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    arrowButton: {
        //margin: theme.spacing(3),
        left: theme.spacing(1),
        color: fontColor,
        flex: 1,
    },
    divArrowButton: {
        display: 'inline-block',
        color: fontColor,
        float: 'left',
        //textAlign: 'right',
        //marginRight: theme.spacing(3),
        marginTop: theme.spacing(0),
    },
    songTitle: {
        display: 'block',
        color: fontColor,
        textAlign: 'right',
        marginRight: theme.spacing(3),
        marginLeft: 'auto',
    },
    icons: {
        color: fontColor,
    },
    typography: {
        padding: theme.spacing(2),
    },
    dialogTitleCopyUrl: {
        marginLeft: theme.spacing(2),
        flex: 1,
        flexGrow: 1,
        textAlign: 'left',
    },
}));

export default function ShareUrl(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const [openSnack, setOpenSnack] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const shareUrl = () => {
        if (navigator.share) {
            navigator.share({url: url})
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            setOpen(true);
        }
    }

    const copyUrl = () => {
        var copyText = document.getElementById('urlToShare');
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");
        setOpen(false);
        setOpenSnack(true);
    }

    let url = document.location.href;
    const canonicalElement = document.querySelector('link[rel=canonical]');
    if (canonicalElement !== null) {
        url = canonicalElement.href;
    };

    return (
        <Fragment>
            <IconButton edge="start" className={classes.icons} onClick={shareUrl}>
                <ShareIcon fontSize='small'/>
            </IconButton>
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title" onClick={(e)=>{e.stopPropagation()}}>   
                <DialogTitle id="form-dialog-title" onClose={handleClose}>
                    {`Share this ${props.type}`}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="urlToShare"
                        value= {url}
                        onClick={(e) => {e.stopPropagation()}}
                        onKeyDown={(e) => {e.stopPropagation();}}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={copyUrl} color="primary">
                        Copy link
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openSnack} autoHideDuration={1000} >
                <Alert variant="filled" severity="success"
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpenSnack(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }>
                    {`URL for the ${props.type} is copied!`}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}