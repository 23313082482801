import React, { Fragment, useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { makeStyles } from '@material-ui/core/styles';

import { fontColor, khaitaColor } from '../Utils/constants';
import getUrlThumbnail from '../Utils/getUrlThumbnail';

const useStyles = makeStyles(theme => ({
    icon: {
        color: fontColor,
        marginLeft: theme.spacing(0),
    },
    myIcon: {
        color: khaitaColor,//'#f50057',
        marginLeft: theme.spacing(0),
    },
}));

export default function AddMyKhaita(props) {
    const classes = useStyles();
    const [my, setMy] = useState(false);

    useEffect(()=> {
        let myKhaita = localStorage.getItem('myKhaita');
        if (myKhaita === null) {
            setMy(false);  
        } else {
            if (myKhaita) {
                try {
                    myKhaita = JSON.parse(myKhaita);
                } catch (ex) {
                    myKhaita = {};
                }
            }
            if (myKhaita[props.item.songUrl] !== undefined) {
                setMy(true);
            } else {
                setMy(false);
            }
        }
    }, [props]);

    const addDeleteMyKhaita = () => {

        if (props.item.video === null) {
            return
        }
   
        let myKhaita = localStorage.getItem('myKhaita');

        if (my) {
            //delete
            if (myKhaita !== null) {
                if (myKhaita) {
                    try {
                        myKhaita = JSON.parse(myKhaita);
                    } catch (ex) {
                        myKhaita = {};
                    }
                }
                delete myKhaita[props.item.songUrl];
                const data = JSON.stringify(myKhaita);
                localStorage.setItem('myKhaita', data);
            };
            setMy(false);
        } else {
            if (myKhaita === null) {
                //create new
                const newItem = {
                    [props.item.songUrl]: {
                        'id': '',
                        'url': props.item.songUrl,
                        'imageUrl': getUrlThumbnail(props.item.video.song),
                        'title': props.item.title,
                        'by': props.item.by,
                        //'date': new Date()
                    }
                };
                const data = JSON.stringify(newItem);
                localStorage.setItem('myKhaita', data);  
            } else {
                if (myKhaita) {
                    try {
                        myKhaita = JSON.parse(myKhaita);
                    } catch (ex) {
                        myKhaita = {};
                    }
                }
                myKhaita = {
                    ...myKhaita,
                    [props.item.songUrl]: {
                        'id': '',
                        'url': props.item.songUrl,
                        'imageUrl': getUrlThumbnail(props.item.video.song),
                        'title': props.item.title,
                        'by': props.item.by,
                        //'date': new Date()
                    }
                }
                const data = JSON.stringify(myKhaita);
                localStorage.setItem('myKhaita', data);
            };
            setMy(true);
        }
    }

    return (
        <Fragment>
            <IconButton 
                edge="start" 
                className={my ? classes.myIcon : classes.icon} 
                onClick={addDeleteMyKhaita}>
                <FavoriteIcon />
            </IconButton>
        </Fragment>
    )
}