import React, { useContext, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import SingleLineCollection from './SingleLineCollection';
import SongListSkeleton from './SongListSkeleton';

import CollectionsContext from '../../CollectionsContext/CollectionsContext';

import shuffleArray from '../../Utils/shuffleArray';

function isEmpty(obj) {
  for (var key in obj) {
    return false;
  }
  return true;
}

function SongList_(props) {
  const { collections, setCollection, updateCollection } =
    useContext(CollectionsContext);

  useEffect(() => {
    if (collections[props.songCollection] === undefined) {
      setCollection(props.songCollection);
    }
  }, [props.songCollection]);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newSongList = Array.from(collections[source.droppableId].songList);

    const movingElement = newSongList.splice(source.index, 1);
    newSongList.splice(destination.index, 0, movingElement[0]);

    updateCollection(props.songCollection, newSongList, true);

    /*const newNavigation = [];
        for (let i = 0; i < newSongList.length; i++) {
            newSongList[i] = {
                ...newSongList[i],
                sort: i
            }
            newNavigation.push(newSongList[i].url);
        };

        const updatedCollection = {
            ...collections[props.songCollection],
            songList: newSongList,
            navigation: newNavigation,
            isShuffled: true,
        }


        updateCollection(props.songCollection, updatedCollection);*/
  };

  const onShuffle = () => {
    const newSongList = shuffleArray(
      Array.from(collections[props.songCollection].songList)
    );

    updateCollection(props.songCollection, newSongList, true);
    /*const newNavigation = [];
        for (let i = 0; i < newSongList.length; i++) {
            newSongList[i] = {
                ...newSongList[i],
                sort: i
            }
            newNavigation.push(newSongList[i].url);
        };

        const updatedCollection = {
            ...collections[props.songCollection],
            songList: newSongList,
            newNavigation: newNavigation,
            isShuffled: true
        }
        updateCollection(props.songCollection, updatedCollection);*/
  };

  const onSort = () => {
    const newSongList = collections[props.songCollection].songList;
    newSongList.sort(function (a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      // a должно быть равным b
      return 0;
    });

    /*const newNavigation = [];
        for (let i = 0; i < newSongList.length; i++) {
            newSongList[i] = {
                ...newSongList[i],
                sort: i
            }
            newNavigation.push(newSongList[i].url);
        };

        const updatedCollection = {
            ...collections[props.songCollection],
            songList: newSongList,
            newNavigation: newNavigation,
            isShuffled: false
        }
        updateCollection(props.songCollection, updatedCollection);*/
    updateCollection(props.songCollection, newSongList, false);
  };

  if (collections[props.songCollection] !== undefined) {
    const songList = collections[props.songCollection].songList;
    const titleCollection = collections[props.songCollection].titleCollection;

    if (songList === null || isEmpty(songList)) {
      return <SongListSkeleton />;
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <SingleLineCollection
            songListArray={songList}
            navigation={collections[props.songCollection].navigation}
            linkBack={props.linkBack}
            songsNumColl={collections[props.songCollection].songsNumColl}
            titleCollection={titleCollection}
            lastSong={props.lastSong}
            songCollectionId={props.songCollection}
            onShuffle={onShuffle}
            onSort={onSort}
            isShuffled={collections[props.songCollection].isShuffled}
          />
        </div>
      </DragDropContext>
    );
  } else {
    return null;
  }
}

const SongList = React.memo(SongList_);

export default SongList;
