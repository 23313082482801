import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { MainNav } from './Layouts';

import CollectionsState from './CollectionsContext/CollectionsState';
import MyKhaitaState from './MyKhaitaContext/MyKhaitaState';
import Progress from './Utils/Progress';

import InstallNotification from './InstallNotifications';

import AutoplaySwitch from './KhaitaPlayer/AutoplaySwitch';
import { getLocalAutoplay, setLocalAutoplay } from './KhaitaPlayer/AutoplayLocal';

function App() { 
    //Autoplay
    const [state, setState] = useState({
        autoplaySwitcher: getLocalAutoplay()
    });

    useEffect(() => {
        setState({ ...state, autoplaySwitcher: getLocalAutoplay()});
    }, []);

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setLocalAutoplay(event.target.checked);
    };

    return (
        <CollectionsState>
        <MyKhaitaState>
            <React.Suspense fallback={<Progress />}>
                <Router>
                    <MainNav 
                        autoplayComponent={
                            <AutoplaySwitch
                                state={state}
                                handleChange={handleChange}
                            />
                        }
                    />
                    <InstallNotification />
                </Router>
            </React.Suspense>
        </MyKhaitaState>
        </CollectionsState>
    );
}

export default App;

