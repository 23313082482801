import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link, useLocation } from 'react-router-dom';


import SongCardSkeleton from './SongCardSkeleton';


const scrollToRef = (ref) => {
    ref.current.scrollIntoView({
        //behavior: 'smooth',
        block: 'start',
    });
} 
const useMountEffect = (fun) => useEffect(fun, [])

const useStyles = makeStyles({
    card: {
        minWidth: 210,
        width: 210,
        margin: '0.5em',
        textDecoration: 'none',
    },
    cardContent: {
        paddingBottom: '0.5em',
        overflow: 'hidden',
        "&:last-child": {
            paddingBottom: '0.5em'
        },
        paddingTop: '0.5em',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    cardRaised: {
        paddingBottom: '0.5em',
        overflow: 'hidden',
        "&:last-child": {
            paddingBottom: '0.5em'
        },
        paddingTop: '0.5em',
        paddingLeft: '1em',
        paddingRight: '1em',
        background: 'linear-gradient(333deg, rgba(82,191,239,0.6096639339329482) 0%, rgba(233,65,85,0.2231093120842087) 100%)'
    },
    cardText: {
        whiteSpace: 'nowrap', 
        overflow: 'hidden',
        textOverflow: 'ellipsis',   
    },
    cardTitle: {
        whiteSpace: 'nowrap', 
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        //fontSize: '1.3em' 
        textAlign: 'left' 
    },
    media: {
        height: 108,
        minWidth: 210,
        width: 210,
        //height: 140,
    },
});

function SongCard(props) {
    const classes = useStyles();

    const myRef = useRef(null);

    const location = useLocation();
    //const [raised, setRaised] = useState(((location.state !== undefined) && (props.item.id === location.state.lastSong)) ? true : false);
    const [raised, setRaised] = useState(((location.state !== undefined) && (props.item.url === location.state.lastSong)) ? true : false);

    useEffect(() => {
        if ((((location.state !== undefined) && (props.item.url === location.state.lastSong)) ? true : false) && props.setLastSongFromCard) {
            //debugger;
            props.setLastSongFromCard(props.item.url)
        };
        //setRaised(((location.state !== undefined) && (props.item.id === location.state.lastSong)) ? true : false);
        setRaised(((location.state !== undefined) && (props.item.url === location.state.lastSong)) ? true : false);
    
    }, [location.state, props]);

    useMountEffect(() => {
        if ((location.state !== undefined) && (props.item.url === location.state.lastSong)) {
            scrollToRef(myRef)
        }
    }); // Scroll on mount

    return props.item === undefined ? (
      <SongCardSkeleton />
    ) : (
      // <Draggable
      //     draggableId={props.item.url}
      //     index={props.index}
      //
      <>
        {/* {(provided)=>( */}
        <Card
          // {...provided.draggableProps}
          // {...provided.dragHandleProps}
          //ref={provided.innerRef}
          // ref={composeRefs(provided.innerRef, myRef)}
          ref={myRef}
          //innerRef={myRef}
          id={props.item.id}
          key={props.item.id}
          className={classes.card}
          //ref={myRef}
          component={Link}
          to={{
            pathname: `/songs/${props.item.url}`,
            state: {
              navigation: props.navigation,
              linkBack: props.linkBack,
              id: props.item.id,
              songsNumColl: props.songsNumColl,
              lastLocationState: props.lastLocationState,
            },
          }}
          raised={raised}
          draggable
          onDragStart={(e) => props.handleDragStart(e, props.index)}
          onDrop={(e) => props.handleDrop(e, props.index)}
        >
          <CardMedia
            className={classes.media}
            image={props.item.imageUrl}
            title={props.item.title.tra}
          />
          <CardContent
            className={raised ? classes.cardRaised : classes.cardContent}
          >
            <Typography
              variant='h6'
              component='h6'
              className={classes.cardTitle}
            >
              {props.item.title.tra}
            </Typography>
            <Typography
              gutterBottom
              variant='body2'
              component='p'
              className={classes.cardText}
            >
              {props.item.by.tra}
            </Typography>
            <Typography
              variant='body1'
              component='p'
              color='textSecondary'
              className={classes.cardText}
            >
              {props.item.title.tib} {props.item.by.tib}
            </Typography>
            <Typography
              variant='caption'
              color='textSecondary'
              component='p'
              align='right'
            >
              {props.item.id}
            </Typography>
          </CardContent>
        </Card>
        {/* )} */}
      </>
    );
}

export default SongCard;