const backgroundColor = '#ffffff';
const fontColor = '#263238';
const accentColor = '#43bfef';
const accentColor2 = '#0f8899';
const khaitaColor = '#cc9b3b';//'#e2ad41';//'#e6ab31';
const greenColor = '#00CC66';

export { backgroundColor, fontColor, 
    accentColor, accentColor2,
    khaitaColor, greenColor
}