import React, { useReducer } from "react"

// Bring the context
import CollectionsContext from "./CollectionsContext"

// Bring the reducer
import CollectionsReducer from "./CollectionsReducer"

// Bring the types
import { SET_COLLECTION } from "./CollectionsTypes"

import { db } from '../firebase';//   '../../firebase';

async function fetchSongList(songCollection, fromDB) {
    const docRef = db.doc('songCollections/'+songCollection);

    let getOptions = {
        source: fromDB ? 'server': 'cache' //'server'
    };
 
    try {
        const doc = await docRef.get(getOptions);
        //const doc = await docRef.get();
        if (doc.exists) {
            return await doc.data();
        }
        else {        
        }
    }
    catch (error) {
        try {
            const doc = await docRef.get();
            if (doc.exists) {
                return await doc.data();
            }
            else {
                //console.log("No such document!", songCollection);
                return undefined;
            }
        }
        catch (error) {
            console.log("Error getting document:", error);
            return error;
        }
    }
};


const CollectionsState = ({ children }) => {
    // Define our state
    const initialState = {
        collections: []
    }

    // Dispatch the reducer
    // This come from useReducer from ReactJS
    const [state, dispatch] = useReducer(CollectionsReducer, initialState)



    // Get collection from db and SET to the state
    const setCollection = async (songCollection, fromDB = false) => {
        const songListData = fetchSongList(songCollection, fromDB);
        songListData.then(async (data) => {
            if (data === undefined || data === null) {
                
            } else {
                const songList = data.songs;
                const songListArray = [];
                const navigation = [];
                let songsNumColl = {};


                const keys = Object.keys(songList);
                keys.sort();

                let i = 0;
                for (let key of keys) {
                    songListArray.push({
                        ...songList[key],
                        sort: i
                    });
                    i++;
                    navigation.push(songList[key].url);
                    songsNumColl = {
                        [songList[key].url]: songList[key].id,
                        ...songsNumColl
                    };
                }
                
                const collectionData = {
                    [songCollection]: {
                        songList: songListArray,
                        titleCollection: data.title,
                        songsNumColl,
                        navigation,
                        isShuffled: false,
                    }
                };

                dispatch({ type: SET_COLLECTION, payload: collectionData })
            }
        });
    }

    //Get collection from the state
    const getCollection = (songCollection) => {
        if (collections[songCollection] === undefined) {
            setCollection(songCollection);
        }
        return collections[songCollection]
    }

    const updateCollection = (songCollection, newSongList, isShuffled) => {

        const newNavigation = [];
        for (let i = 0; i < newSongList.length; i++) {
            newSongList[i] = {
                ...newSongList[i],
                sort: i
            }
            newNavigation.push(newSongList[i].url);
        };

        const collectionData = {
            [songCollection]: {
                ...collections[songCollection],
                songList: newSongList,
                navigation: newNavigation,
                isShuffled
            }
        };

        dispatch({ type: SET_COLLECTION, payload: collectionData })
    }

    // Destruct the states
    const { collections } = state;

    // Here's where we gonna use this state and funcitons to dealing with the context
    // The context will wrapping our entire application with this component and accept children in it
    // Anything state or function, must be passed in to value props in this provider in order to use it
    // NOTE: PLEASE NOTICE, IF YOU DIDN'T PASS THE STATE OR THE FUNCTION in THIS VALUE PROPS, YOU WILL GET AN ERROR
    return (
        <CollectionsContext.Provider
            value={{
                collections,
                getCollection,
                setCollection,
                updateCollection,
            }}
        >
            {children}
        </CollectionsContext.Provider>
    )
}

export default CollectionsState