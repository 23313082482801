import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';

import SongCard from '../KhaitaCollections/SongCard/SongCard';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '1.5em',
        minHeight: '80vh',
    },
    container: {
        marginTop: theme.spacing(4),
    }
}));

export default function DzamlinggarSong (props) {
    const classes = useStyles();

    const onDragEnd = result => {
        //do nothing     
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" className={classes.container}>
                <DragDropContext
                    onDragEnd={onDragEnd}
                >
                    <Droppable droppableId={'dzg'}>
                        {(provided)=>(
                            <Grid
                                container
                                alignItems="center"
                                innerRef={provided.innerRef}
                                {...provided.droppableProps}
                            >        
                                <SongCard 
                                    key={'dzg'} 
                                    item={{
                                        by: {
                                            tib: '',
                                            tra: ''
                                        },
                                        title: {
                                            tib: 'འཛམ་གླིང་སྒར་གླུ་བྲོ། ',
                                            tra: 'Dzamling Gar'
                                        },
                                        url: 'dzamling-gar',
                                        id: '1',
                                        imageUrl: 'https://i.ytimg.com/vi/pB1vlCJ0I1k/0.jpg'
                                    }} 
                                    navigation={['dzamling-gar']} 
                                    linkBack={'/collections/dzamlinggar'} 
                                    index={1}
                                />               
                                {provided.placeholder} 
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Container>
        </div>
    )
}