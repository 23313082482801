import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { IconButton } from '@material-ui/core';
import { Typography } from '@material-ui/core/';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import Progress from '../Utils/Progress';
import { DialogTitle, DialogContent, Transition } from '../Utils/Dialog';

import { db } from '../firebase';
import { fontColor } from '../Utils/constants';

const useStyles = makeStyles(theme => ({
    dialogContent: {
        padding: 0
    },
    icons: {
        color: fontColor,
        //paddingRight: 0,
    },
}));

export default function SongInfo(props) {
    const [openInfo, setOpenInfo] = useState(false);
    const [collArray, setCollArray] = useState([]);

    const classes = useStyles();

    const handleOpenInfo = () => {
        updateCollArray(props.songUrl);
        setOpenInfo(true);
    }

    const handleClose = () => {
        setOpenInfo(false);
    };

    async function updateCollArray(url) {
        const collArray = await findCollectionsWithSong(url);
        for (let id in collArray) {
            collArray[id] = findLinkAndParent(collArray[id]);
        }
        //console.log(collArray);
        setCollArray(collArray);
    }

    const findLinkAndParent = (song) => {
        if (song.collTitle.startsWith('Metreng 1')) {
            return {
                ...song,
                link: '/collections/mekhor/M1',
                parent: 'Mekhor'
            }
        } else if (song.collTitle.startsWith('Metreng 2')) {
            return {
                ...song,
                link: '/collections/mekhor/M2',
                parent: 'Mekhor'
            }
        } else if (song.collTitle.startsWith('Metreng 3')) {
            return {
                ...song,
                link: '/collections/mekhor/M3',
                parent: 'Mekhor'
            }
        } else if (song.idInColl.startsWith('B')) {
            return {
                ...song,
                link: '/collections/barsam',
                parent: 'Barsam'
            }
        } else if (song.idInColl.startsWith('T')) {
            return {
                ...song,
                link: '/collections/message',
                parent: 'Message from Tibet'
            }
        } else if (song.collTitle === 'Amdo' || 
            song.collTitle === 'Golog' ||
            song.collTitle === 'Khampa' ||
            song.collTitle === 'Markham'
            ) {
            return {
                ...song,
                link: 'collections/circle',
                parent: 'Circle dances'
            }
        } else if (song.collTitle === 'Children') {
            return {
                ...song,
                link: '/collections/children',
                parent: ''
            } 
        }
    }

    async function findCollectionsWithSong(url) {
        let collArray = [];
        await db.collection('songCollections').where('songsIdArray', 'array-contains', url)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                for (let song in doc.data().songs) {
                    if (doc.data().songs[song].url === url) {
                        collArray.push({
                            collTitle: doc.data().title,
                            idInColl: doc.data().songs[song].id,
                        });
                        break;
                    }
                }
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
        return collArray;
    }

    return (
        <>
            <IconButton 
                aria-label='info' 
                className={classes.icons}
                onClick={handleOpenInfo}
            >
                <InfoIcon />
            </IconButton>
            <Dialog
                open={openInfo}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth
                maxWidth='xs'
                //aria-labelledby="alert-dialog-slide-title"
                //aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id='song-info' onClose={handleClose}>
                    {props.songTitle}
                    <Typography variant='body1' component='p' color='textSecondary'>
                        {props.by}
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <List>
                        {collArray.length === 0 && <Progress/>}
                        {collArray.map((item, index)=>
                            <ListItem 
                                button 
                                key={index} 
                                component={Link} 
                                to={{
                                    pathname: item.link,
                                    state: {lastSong: props.songUrl}
                                }}
                            >
                                <ListItemText 
                                    primary={item.parent === '' 
                                        ? item.collTitle
                                        : `${item.parent} / ${item.collTitle}`} 
                                    secondary={item.idInColl}
                                />
                            </ListItem>
                        )}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
}