import React, { useReducer } from "react"

// Bring the context
import MyKhaitaContext from "./MyKhaitaContext"

// Bring the reducer
import MyKhaitaReducer from "./MyKhaitaReducer"

// Bring the types
import { SET_MYKHAITA } from "./MyKhaitaTypes"

import shuffleArray from '../Utils/shuffleArray';


const MyKhaitaState = ({ children }) => {
    // Define our state
    const initialState = { 
        listMyKhaita: [],
        navigation: [],
        songsNumColl: {},
        isShuffled: false, 
        lastSong: undefined      
    }

    //const location = useLocation();

    // Dispatch the reducer
    // This come from useReducer from ReactJS
    const [state, dispatch] = useReducer(MyKhaitaReducer, initialState)

    const updateState = (isShuffled, lastSongUrl) => {
        //debugger;
        let myKhaita = localStorage.getItem('myKhaita');
        if (myKhaita === null) {
            const stateData = {
                listMyKhaita: [],
                navigation: [],
                songsNumColl: {},
                isShuffled: false,
                lastSong: undefined
            }
            dispatch({ type: SET_MYKHAITA, payload: stateData })
        } else {
            if (myKhaita) {
                try {
                    myKhaita = JSON.parse(myKhaita);
                    
                    const listMyKhaita = [];
                    const navigation = [];
                    let songsNumColl = {};
                    let shortList = [];
                    let i = 1;
                    let j = 1;
                    let lastSong = undefined;

                    const keys = isShuffled 
                        ? shuffleArray(Object.keys(myKhaita))
                        : Object.keys(myKhaita).sort();
                    
                    for (let item of keys) {
                        shortList.push({
                            ...myKhaita[item],
                            id: i.toString(),
                        });
                        j++;
                        if (j === 6) {
                            listMyKhaita.push(shortList);
                            shortList = [];
                            j = 1;
                        }
                        songsNumColl = {
                            [myKhaita[item].url]: i.toString(),
                            ...songsNumColl
                        };
                        navigation.push(myKhaita[item].url);
                        //debugger;
                        /*if (myKhaita[item].url === lastSongUrl) {
                            lastSong = `${i}`;
                        }*/
                        i++;

                    };
                    if (shortList.length > 0) {
                        listMyKhaita.push(shortList);
                    }
                    const stateData ={
                        listMyKhaita: listMyKhaita, 
                        navigation: navigation,
                        songsNumColl: songsNumColl,
                        isShuffled,
                        lastSong: lastSong
                    } 
                    dispatch({ type: SET_MYKHAITA, payload: stateData })
                } catch (ex) {
                    const stateData = {
                        listMyKhaita: [],
                        navigation: [],
                        songsNumColl: {},
                        isShuffled: false,
                        lastSong: undefined
                    }
                    dispatch({ type: SET_MYKHAITA, payload: stateData })
                }
            }      
        }
    }

    // Destruct the states
    const { 
        listMyKhaita,
        navigation,
        songsNumColl,
        isShuffled,
        lastSong
    } = state;

    // Here's where we gonna use this state and funcitons to dealing with the context
    // The context will wrapping our entire application with this component and accept children in it
    // Anything state or function, must be passed in to value props in this provider in order to use it
    // NOTE: PLEASE NOTICE, IF YOU DIDN'T PASS THE STATE OR THE FUNCTION in THIS VALUE PROPS, YOU WILL GET AN ERROR
    return (
        <MyKhaitaContext.Provider
            value={{
                listMyKhaita,
                navigation,
                songsNumColl,
                isShuffled,
                lastSong,
                updateState,
            }}
        >
            {children}
        </MyKhaitaContext.Provider>
    )
}

export default MyKhaitaState