import React from 'react'

const MyKhaitaContext = React.createContext({
    listMyKhaita: [],
    navigation: [],
    songsNumColl: {},
    isShuffled: false,
    lastSong: undefined
});

export default MyKhaitaContext