import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import KhaitaCollections from '../KhaitaCollections/KhaitaCollections';
import SongPage from '../SongPage/SongPage';
//import Search from '../Search/Search';

//import AboutKhaita from '../Info/AboutKhaita';
const AboutKhaita = React.lazy(() => import('../Info/AboutKhaita'));
//import AroundTheWorld from '../AroundTheWorld/AroundTheWorld';
// const AroundTheWorld = React.lazy(() => import('../AroundTheWorld/AroundTheWorld'));
//import DanceVideoPage from '../AroundTheWorld/DanceVideoPageContainer';
const DanceVideoPage = React.lazy(() =>
  import('../AroundTheWorld/DanceVideoPageContainer')
);
//import DanceSchool from '../Info/DanceSchool';
// const DanceSchool = React.lazy(() => import('../Info/DanceSchool'));
//import OurTeam from '../Info/OurTeam';
const OurTeam = React.lazy(() => import('../Info/OurTeam'));
//import ContactUs from '../Info/ContactUs';
const ContactUs = React.lazy(() => import('../Info/ContactUs'));
//import Donate from '../Info/Donate';
const Donate = React.lazy(() => import('../Info/Donate'));
const Update = React.lazy(() => import('../Utils/Update'));
// const Calendar = React.lazy(() => import('../Info/Calendar'));

function KhaitaMain(props) {
  return (
    <Fragment>
      <CssBaseline />
      <Switch>
        <Route path='/collections'>
          <KhaitaCollections />
        </Route>
        <Route path='/songs/:url'>
          <SongPage
            state={props.state}
            handleChange={props.handleChange}
            //autoplayComponent={props.autoplayComponent}
          />
        </Route>
        {/*<Route path='/search'>
                    <Search/>
                </Route>*/}
        <Route path='/about'>
          <AboutKhaita />
        </Route>
        {/* <Route path='/aroundtheworld'>
                    <AroundTheWorld/>
                </Route> */}
        <Route path='/dancevideo/:url'>
          <DanceVideoPage />
        </Route>
        {/*<Route path='/danceschool'>
                    <DanceSchool/>
                </Route>*/}
        {/* <Route path='/calendar'>
                    <Calendar/>
                </Route> */}
        <Route path='/ourteam'>
          <OurTeam />
        </Route>
        <Route path='/contact'>
          <ContactUs />
        </Route>
        <Route path='/donate'>
          <Donate />
        </Route>
        <Route path='/update'>
          <Update />
        </Route>
        <Redirect to='/collections' />
      </Switch>
    </Fragment>
  );
}

export default KhaitaMain;
