import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';

// import Search from '../Search/Search';
import SearchToolbar from '../Search/SearchToolbar';

import { fontColor } from '../Utils/constants';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundImage: `url('/android-chrome-192x192.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(0),
    color: fontColor,
  },
  searchButton: {
    color: fontColor,
  },
  hide: {
    display: 'none',
  },
  search: {
    flexGrow: 1,
    maxWidth: '600px',
  },
  flex: {
    flexGrow: 1,
  },
}));

function MainNavToolbar(props) {
  const classes = useStyles();
  // const [openSearch, setOpenSearch] = useState(false);
  const { width } = props; //xs sm

  if (props.openSearch) {
    //search toolbar
    // return (
    //     <Toolbar >
    //         {width !== 'xs' &&
    //             <IconButton
    //                 aria-label="open drawer"
    //                 onClick={props.toggleDrawer(true)}
    //                 edge="start"
    //                 className={clsx(classes.menuButton, props.open && classes.hide)}
    //             >
    //                 <MenuIcon />
    //             </IconButton>
    //         }
    //         <ClickAwayListener onClickAway={()=>{setOpenSearch(false)}}>
    //             <div style={{display: 'contents'}}>
    //                 <IconButton
    //                     onClick={()=>{setOpenSearch(!openSearch)}}
    //                 >
    //                     <SearchIcon className={classes.searchButton} />
    //                 </IconButton>
    //                 <div className={classes.search}>
    //                     <Search closeSearch={()=>setOpenSearch(false)}/>
    //                 </div>
    //             </div>
    //         </ClickAwayListener>
    //         {width !== 'xs' &&
    //             <>
    //                 <div className={classes.flex}/>
    //                 <Button
    //                     component={Link}
    //                     to={{pathname: `/donate`}}
    //                 >
    //                     Donate
    //                 </Button>
    //             </>
    //         }
    //     </Toolbar>
    // )
    return (
      <SearchToolbar
        width={width}
        openSearch={props.openSearch}
        setOpenSearch={props.setOpenSearch}
        toggleDrawer={props.toggleDrawer}
        open={props.open}
      />
    );
  } else {
    //normal toolbar
    return (
      <Toolbar className={classes.toolbar}>
        <IconButton
          aria-label='open drawer'
          onClick={props.toggleDrawer(true)}
          edge='start'
          className={clsx(classes.menuButton, props.open && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            props.setOpenSearch(!props.openSearch);
          }}
        >
          <SearchIcon className={classes.searchButton} />
        </IconButton>
        <IconButton
          component={Link}
          to={{
            pathname: `/`,
          }}
        >
          <HomeIcon className={classes.searchButton} />
        </IconButton>
        <div className={classes.flex} />
        <Button component={Link} to={{ pathname: `/donate` }}>
          Donate
        </Button>
      </Toolbar>
    );
  }
}

MainNavToolbar.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(MainNavToolbar);
