import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import LaunchIcon from '@material-ui/icons/Launch';

import { fontColor } from '../../Utils/constants';

const useStyles = makeStyles((theme) => ({
    goToDiv: {
        float: 'right',
        marginRight: theme.spacing(2)
    },
    goToLabel: {
        fontSize: '0.5rem'
    },
    goToRoot: {
        padding: '1px'
    }
}));

export default function CurrentSongInfo (props) {
    /*if (props.error) {
        return (
            <div style={{paddingLeft: '24px'}}>
                <Typography 
                    variant="h6" 
                    component="h6" 
                    style={{textAlign: 'left'}} 
                    color='secondary'
                >
                    Failed to load video
                </Typography>
                <Typography 
                    gutterBottom 
                    variant="body2" 
                    component="p" 
                    color='secondary'
                >
                    Check the Internet connection
                </Typography>
            </div>
        )
    }
    console.log(props);*/
    return (
        <div style={{paddingLeft: '24px'}}>
            <Link
                to={{
                        pathname: `/songs/${props.currentVideo.url}`,
                        state: { 
                            navigation: props.navigation,
                            linkBack: props.linkBack,
                            //id: props.item.id,
                            songsNumColl: props.songsNumColl,
                        }
                    }}
                style={{
                    textDecoration: 'none',
                    color: fontColor
                }}
            >
                <GoToIcon/>
                <Typography 
                    variant="h6" 
                    component="h6" 
                    style={{textAlign: 'left'}}
                >
                    {props.currentVideo.title}
                </Typography>
                <Typography 
                    gutterBottom 
                    variant="body2" 
                    component="p" 
                    color='textSecondary'
                >
                    {props.currentVideo.by}
                </Typography>
            </Link>
        </div>
    )
}

function GoToIcon () {
    const classes = useStyles();
    return (
        <div className={classes.goToDiv} >
            <BottomNavigation showLabels>
                <BottomNavigationAction 
                    label='DISCOVER MORE' 
                    icon={<LaunchIcon />} 
                    classes={{
                        label: classes.goToLabel,
                        root: classes.goToRoot,
                    }}
                />
            </BottomNavigation>
        </div>
    )
}