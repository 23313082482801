import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles'

import SingleLineMyKhaita from './SingleLineMyKhaita';
import WelcomeToMyKhaita from './WelcomeToMyKhaita';
import KhaitaPlayerSettings from '../KhaitaPlayer/KhaitaPlayerSettings';
import { backgroundColor } from '../Utils/constants';

import MyKhaitaContext from '../MyKhaitaContext/MyKhaitaContext';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: ' 0 0 4px 0 rgba(0, 0, 0, 0.12)',
        backgroundColor: backgroundColor,
        minHeight: '85vh',
    },
    khaitaContainer: {
        maxWidth: 'fit-content'
    },
    collectionControls: {
        display: 'inline-table',
        width: '95%',
        marginTop: '2em'
    }
}));

function MyKhaita () {
    const { 
        listMyKhaita,
        navigation,
        songsNumColl,
        isShuffled,
        updateState
    } = useContext(MyKhaitaContext);

    const [lastSongUrl, setLastSongUrl] = useState(undefined);

    const location = useLocation();

    useEffect(() => {
        // if (listMyKhaita.length === 0) {
            updateState(false)
        // }
    }, []);

    const onDragEnd = () => {
        // console.log('on drag end my khaita');
    }
    const onShuffle = () => {
        updateState(true);
    }
    const onSort = () => {
        updateState(false);
    }

    const setLastSongFromCard = (url) => {
        setLastSongUrl(url);
    }

    const classes = useStyles();

    if (location.state !== undefined
        && lastSongUrl !== undefined) 
        {
        location.state.lastSong = lastSongUrl;
    }

    if (listMyKhaita === undefined || listMyKhaita.length === 0) {
        return <WelcomeToMyKhaita/>
    } else {
        return (
            <div className={classes.root}>
                <div className={classes.khaitaContainer}>
                    <div className={classes.collectionControls}>
                        <KhaitaPlayerSettings 
                            onShuffle={onShuffle}
                            onSort={onSort}
                            isShuffled={isShuffled}
                            url={`khaitaplayer_mykhaita`}
                            songList={navigation}
                            titleCollection={'My Khaita'}
                            songsNumColl={songsNumColl}
                            linkBack={'/collections/mykhaita'}
                        />
                    </div>
                    <DragDropContext
                        onDragEnd={onDragEnd}
                    >
                        {listMyKhaita.map((list, index) =>                     
                            <SingleLineMyKhaita
                                key={index}
                                songListArray={list} 
                                navigation={navigation} 
                                linkBack={'/collections/mykhaita'}
                                songsNumColl={songsNumColl}
                                titleCollection={''}
                                songCollectionId={`myKhaita${index}`}
                                setLastSongFromCard={setLastSongFromCard}
                            />              
                        )}
                    </DragDropContext>
                </div>
            </div>        
        )
    }
}

export default MyKhaita