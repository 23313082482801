import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

import SocialButtons from '../Info/SocialButtons';

const useStyles = makeStyles(theme => ({
    centered: {
        textAlign: 'center',
    },
    marginAdded: {
        margin: theme.spacing(0.5),
    }
}));

export default function Footer() {
    const classes = useStyles();
    return (
        <div className={classes.centered}>
            <div className={classes.marginAdded}>
                <SocialButtons />
            </div>
            <Link  
                href="https://atiyogafoundation.net" 
                target="_blank"
                rel="noopener"
                variant="caption"
                underline='none'
            >
                {` International Atiyoga Foundation `}
            </Link>
        </div>
    )
}