const getLocalAutoplay = () => {
    const autoplay = localStorage.getItem('autoplay');
    if (autoplay === 'true') {
        return true;
    }
    return false;
}

const setLocalAutoplay = (newAutoplay) => {
    localStorage.setItem('autoplay', newAutoplay);
}

export { getLocalAutoplay, setLocalAutoplay }