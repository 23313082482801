import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CollectionTabs from './CollectionTabs/CollectionTabs';
import Footer from '../Layouts/Footer';

import { withRouter } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(1),
        boxShadow: ' 0 0 4px 0 rgba(0, 0, 0, 0.12)',
        minHeight: '100vh',
    },
}));

function KhaitaCollections() {
    const classes = useStyles();

    return(  
        <div className={classes.root}>
            <CollectionTabs />
            <Footer />
        </div>
    );
}

export default withRouter(KhaitaCollections);