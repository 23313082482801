import {
    SET_COLLECTION
} from "./CollectionsTypes"
  
export default (state, { type, payload }) => {
    switch (type) {
        // Add new collection
        case SET_COLLECTION:
            return {
                collections: {
                    ...state.collections,
                    ...payload,
                }
            }
        default:
            return state
    }
} 