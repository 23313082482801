import React, { useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { DialogTitle, DialogContent, Transition } from './Dialog';
import { db } from '../firebase';
import CollectionsContext from '../CollectionsContext/CollectionsContext';
import Progress from './Progress';
import Success from './Success';
import { fontColor } from './constants';

const useStyles = makeStyles(theme => ({
    dialogContent: {
        padding: 0
    },
    icons: {
        color: fontColor,
        //paddingRight: 0,
    },
}));

export default function UpdateCollections(props) {
    const [open, setOpen] = useState(false);
    //const [collArray, setCollArray] = useState([]);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const { setCollection } = useContext(CollectionsContext)

    const classes = useStyles();

    const handleOpen = (event) => {
        event.stopPropagation();
        setOpen(true);
        updateCollections();
    }

    const handleClose = () => {
        setOpen(false);
    };

    async function fetchCollections() {
        //console.log('fetch collections structure');
        const docRef = await db.doc('songCollections/collectionsStructure');
        return docRef.get().then(async function(doc) {
            if (doc.exists) {
                return await doc.data();
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
            return error;
        });
    };

    const updateSubtree = (subtree) => {
        if (subtree.id) {
            setCollection(subtree.id, true);
        }
        for (let subColl in subtree.collections) {
            updateSubtree(subtree.collections[subColl]);
        }
    }

    const updateCollections = () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            const songCollections = fetchCollections();
            songCollections.then((data) => {
                for (let subtree in data) {
                    updateSubtree(data[subtree]);
                }
            }).then(() => {
                setSuccess(true);
                setLoading(false);
            })
        }
    }
    

    return (
        <>
            <ListItem 
                button 
                key='update' 
                onClick={handleOpen}
            >
                <ListItemText primary='Update' className={props.classesMenu}/>
            </ListItem> 
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth
                maxWidth='xs'
                //aria-labelledby="alert-dialog-slide-title"
                //aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id='song-info' onClose={handleClose}>
                    {success
                        ? 'All collections are updated!'
                        : 'Updating collections...'
                    }
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {loading && <Progress/>}
                    {success && <Success/>}
                </DialogContent>
            </Dialog>
        </>
    );
}